const initBurgerHeader = () => {
  const burgerButton = document.querySelector('.header__hamburger'),
    burgerMenu = document.querySelector('.popup-mob'),
    links = document.querySelectorAll('.popup__link'),
    body = document.body;

  if (!burgerButton) {
    return;
  }

  burgerButton.addEventListener('click', () => {
    if (burgerButton.classList.contains('active')) {
      burgerButton.classList.remove('active');
      burgerMenu.classList.remove('active-popup');
      body.style.overflow = 'visible';
    } else {
      burgerButton.classList.add('active');
      burgerMenu.classList.add('active-popup');
      body.style.overflow = 'hidden';
    }
  });

  links.forEach((item) => {
    item.addEventListener('click', () => {
      burgerButton.classList.remove('active');
      burgerMenu?.classList.remove('active-popup');
      body.style.overflow = 'visible';
    });
  });

  document.addEventListener('click', (event) => {
    const target = event.target;

    if (target.closest('.popup-mob__container')) {
      burgerButton.classList.remove('active');
      burgerMenu?.classList.remove('active-popup');
      body.style.overflow = 'visible';
    }
  });
};

export default initBurgerHeader;
