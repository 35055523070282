'use strict';

/* Стили */
import '../css/site.scss';

/* Скрипты */
import initVisibleContent from './modules/init-visible-content.js';
import sliders from './modules/sliders/sliders.js';
import initBurgerHeader from './modules/init-burger-header.js';
import initDressModal from './modules/init-dress-modal.js';
import initScroll from './modules/init-scroll.js';
import setCheckboxes from './modules/set-checkboxes.js';
import initTeachers from './modules/init-teachers.js';
import intlTelInput from './modules/int-tel-input.js';

document.addEventListener('DOMContentLoaded', () => {
  initVisibleContent('.features-block__span', '.features-block__wrap');
  initVisibleContent('.program-block__span', '.program-block__wrap');
  sliders();
  initBurgerHeader();
  initDressModal();
  initScroll();
  initTeachers();
  intlTelInput();
  setCheckboxes(
    [
      document.querySelector('#form-check-first'),
      document.querySelector('#form-check-second'),
    ],
    document.querySelector('#mainSendButton')
  );
});
