import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Pagination, Navigation]);

const initNewsSlider = (idx) => {
  const slider = document.querySelector(`.news__slider-${idx}`);

  new Swiper(slider, {
    observer: true,
    observeParents: true,
    spaceBetween: 23,
    breakpoints: {
      1281: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      350: {
        slidesPerView: 1.4,
        spaceBetween: 10,
        centeredSlides: true,
        initialSlide: 1,
      },
      0: {
        slidesPerView: 1.4,
        spaceBetween: 10,
        centeredSlides: true,
        initialSlide: 1,
      },
    },
    navigation: {
      nextEl: `.news__next-${idx}`,
      prevEl: `.news__prev-${idx}`,
      disabledClass: 'last',
      lockClass: 'disabled_swiper',
    },
    pagination: {
      el: `.news__pagination-${idx}`,
      type: 'progressbar',
      lockClass: 'disabled_swiper',
    },
  });
};

export default initNewsSlider;
