const initScroll = () => {
  document.querySelectorAll('a[href^="#"').forEach((link) => {
    link.addEventListener('click', function (e) {
      e.preventDefault();

      const href = this.getAttribute('href').substring(1),
        scrollTarget = document.getElementById(href);

      scrollTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    });
  });
};

export default initScroll;
