const initDressModal = () => {
  const buttonsOn = Array.from(document?.querySelectorAll('.form__question')),
    content = document?.querySelector('.dress'),
    buttonClose = document?.querySelector('.close-dress');

  buttonsOn.forEach((item) => {
    item.addEventListener('click', () => {
      content.classList.add('on');
      document.body.style.overflow = 'hidden';
    });
  });

  if (buttonClose) {
    buttonClose.addEventListener('click', () => {
      content.classList.remove('on');
      document.body.removeAttribute('style');
    });
  }
};

export default initDressModal;
