let resize = (func) => {
  let currentInnerWidth = window?.innerWidth;

  window.addEventListener('resize', () => {
    if (window.innerWidth === currentInnerWidth) return;
    currentInnerWidth = window.innerWidth;
    func();
  });
}

export default resize;