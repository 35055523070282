import initTeamSlider from './init-team-slider';
import initJoinSlider from './init-join-slider';
import initVideoSlider from './init-video-slider';
import initReviewSlider from './init-review-slider';
import initTeachersSlider from './init-teachers-slider';
import initNewsSlider from './init-news-slider';

const sliders = () => {
  initTeamSlider();
  initJoinSlider();
  initVideoSlider();
  initReviewSlider();
  initTeachersSlider();
  initNewsSlider(1);
  initNewsSlider(2);
};

export default sliders;
