import Swiper, { Pagination, Navigation, Grid } from 'swiper';
Swiper.use([Pagination, Navigation, Grid]);

const initTeachersSlider = () => {
  const slider = document.querySelector('.teachers__swiper');

  new Swiper(slider, {
    breakpoints: {
      1281: {
        slidesPerView: 2,
        spaceBetween: 24,
        grid: {
          rows: 2,
          fill: 'row',
        },
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 15,
        grid: {
          rows: 2,
          fill: 'row',
        },
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      350: {
        slidesPerView: 1.2,
        spaceBetween: 24,
      },
      0: {
        slidesPerView: 1.1,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: '.teachers__next',
      prevEl: '.teachers__prev',
      disabledClass: 'last',
    },
    pagination: {
      el: '.teachers__pagination',
      type: 'progressbar',
    },
  });
};

export default initTeachersSlider;
