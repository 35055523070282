const intTelInput = () => {
  /* Инициализация поля телефона */
  const listCountries = [
    'az',
    'am',
    'by',
    'kz',
    'kg',
    'md',
    'ru',
    'tj',
    'tm',
    'uz',
  ];

  class Phone {
    constructor(options) {
      this.$el = document.querySelector(options.id);
      this.iti = options.iti;
    }

    setupMaskPhone() {
      if (this.$el) {
        this.iti = window.intlTelInput(this.$el, {
          separateDialCode: true,
          preferredCountries: ['ru'],
          initialCountry: 'auto',
          geoIpLookup: function (callback) {
            fetch('https://ipapi.co/json')
              .then(function (res) {
                return res.json();
              })
              .then(function (data) {
                if (!listCountries.includes(data.country_code)) {
                  callback('ru');
                } else {
                  callback(data.country_code);
                }
              })
              .catch(function () {
                callback('ru');
              });
          },
          utilsScript: '/lib/intlTelInput/utils.js',
        });

        const setIntervalStartPlaceholder = setInterval(() => {
          let placeholderInputPhone = this.$el.placeholder;

          if (placeholderInputPhone != '') {
            let maskNumber = placeholderInputPhone.replace(/[0-9+]/gi, '9');

            $(this.$el).mask(maskNumber, { autoclear: false });

            clearInterval(setIntervalStartPlaceholder);
          }
        }, 500);

        this.$el.addEventListener('countrychange', function () {
          let placeholderInputPhone = document.getElementById(
            this.id
          ).placeholder;
          let maskNumber = placeholderInputPhone.replace(/[0-9+]/gi, '9');

          $('#' + this.id).mask(maskNumber, { autoclear: false });
        });
      }
    }
  }

  const phoneFirst = new Phone({
    iti: 'itiPhone',
    id: '#FirstLessonPhone',
  });

  phoneFirst.setupMaskPhone();

  const phoneSecond = new Phone({
    iti: 'itiPhone',
    id: '#Phone',
  });
  phoneSecond.setupMaskPhone();

  let phoneField = document.querySelector('input[name="Phone"]'),
    button = document.querySelector('.send-button');

  button.addEventListener('click', () => {
    if (phoneFirst.$el !== null) {
      phoneField.value = phoneFirst.iti.getNumber();
    }

    if (phoneSecond.$el !== null) {
      phoneField.value = phoneSecond.iti.getNumber();
    }
  });
};

export default intTelInput;
