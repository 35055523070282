import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Pagination, Navigation]);
import resize from '../resize';

const initTeamSlider = () => {
  const slider = document.querySelector('.team__blocks');

  let swipe = null;

  function init() {
    if (!swipe) {
      swipe = new Swiper(slider, {
        observer: true,
        observeParents: true,
        loop: false,
        spaceBetween: 10,
        breakpoints: {
          350: {
            slidesPerView: 1.3,
          },
          0: {
            slidesPerView: 1.2,
          },
        },
      });
    }
  }

  function destroy() {
    if (swipe) {
      swipe.destroy();
      swipe = null;
    }
  }

  function resizeSwiper() {
    window.innerWidth <= 1280 ? init() : destroy();
  }

  slider && resizeSwiper();
  slider && resize(resizeSwiper);
};

export default initTeamSlider;
