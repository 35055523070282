import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Pagination, Navigation]);

const initVideoSlider = () => {
  const slider = document.querySelector('.video__blocks');

  new Swiper(slider, {
    observer: true,
    observeParents: true,
      
    breakpoints: {
      768: {
        allowTouchMove: false,
        slidesPerView: 2,
        spaceBetween: 24,
      },
      350: {
        slidesPerView: 1.1,
        spaceBetween: 10,
      },
      0: {
        spaceBetween: 10,
        slidesPerView: 1,
      },
    },
    navigation: {
      nextEl: '.video__next',
      prevEl: '.video__prev',
    },
    pagination: {
      el: '.video__pagination',
      type: 'progressbar',
    },
  });
};

export default initVideoSlider;
