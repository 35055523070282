const initVisibleContent = (button, block) => {
  
  const buttons = document?.querySelectorAll(button),
    conatainers = Array.from(document?.querySelectorAll(block));

  buttons.forEach((item, idx) => {
    item.addEventListener('click', () => {
      conatainers[idx]?.classList.toggle('transform');
    });
  });
};

export default initVisibleContent;
