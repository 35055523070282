import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Pagination, Navigation]);

const initReviewSlider = () => {
  const sliderContainers = document.querySelectorAll('.review');
  sliderContainers.length &&
    sliderContainers.forEach((sliderContainer) => {
      const slider = sliderContainer.querySelector('.review__blocks');
      new Swiper(slider, {
        observer: true,
        observeParents: true,
        loop: false,

        breakpoints: {
          1681: {
            slidesPerView: 2,
            spaceBetween: 43,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
          350: {
            slidesPerView: 1.1,
            spaceBetween: 24,
          },
          0: {
            spaceBetween: 24,
            slidesPerView: 1,
          },
        },
        navigation: {
          nextEl: '.review__next',
          prevEl: '.review__prev',
          disabledClass: 'last',
        },
        pagination: {
          el: '.review__pagination',
          type: 'progressbar',
        },
      });
    });
};

export default initReviewSlider;
