const initTeachers = () => {
  const teachersWrapper = document?.querySelector('.teachers__swiper-wrapper'),
    teachersInfoWrapper = document?.querySelector('.teachers__left');

  function toggleTeachers() {
    let slides = Array.from(document?.querySelectorAll('.teachers-slide')),
      blocks = Array.from(document?.querySelectorAll('.teachers-info'));

    slides.forEach((item, idx) => {
      blocks[0].classList.add('active');

      item.addEventListener('click', function () {
        blocks.map((it) => {
          it.classList.remove('active');
        });
        blocks[idx]?.classList.toggle('active');
      });
    });
  }

  if (teachersWrapper && teachersInfoWrapper) {
    setTimeout(toggleTeachers, 1000);
  }
};

export default initTeachers;
