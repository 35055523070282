import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Pagination, Navigation]);

const initJoinSlider = () => {
  const slider = document.querySelector('.join__blocks');

  new Swiper(slider, {
    observer: true,
    observeParents: true,
    breakpoints: {
      1281: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      350: {
        slidesPerView: 1.16,
        spaceBetween: 12,
      },
      0: {
        slidesPerView: 1,
        spaceBetween: 12,
      },
    },
    navigation: {
      nextEl: '.join__next',
      prevEl: '.join__prev',
      disabledClass: 'last',
    },
    pagination: {
      el: '.join__pagination',
      type: 'progressbar',
    },
  });
};

export default initJoinSlider;
