/* Блокировка кнопки отправки формы */

const setCheckboxes = (arr, btn) => {
  try {
    arr.forEach((item) => {
      item.addEventListener('change', function () {
        if (!arr.find((checkbox) => !checkbox.checked)) {
          btn.removeAttribute('disabled');
        } else {
          btn.setAttribute('disabled', 'disabled');
        }
      });
    });
  } catch (e) {}
};

export default setCheckboxes;

